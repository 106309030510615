<template>
    <div class="re-register">
        <div style="position: absolute; top: 66px; left: 79px;" v-if="false">
            <img src="../../assets/logo/waas@2x_white.png" alt="物联科技" style="min-height: 19px; width: 83px;vertical-align: middle;">
            <span style="font-size: 19px;color: #fff;font-weight: bold;vertical-align: middle;">物流云执行体系</span>
          </div>
        <div class="login-content">
            <div class="login-main">
                <img class="wms-logo" :style="[pageSetting.logo.loginLogoStyle]" v-bind:src="loginLogo"/>
                <form>
                    <ul>
                        <li>
                            <div class="border">
                                <span class="input-icon">
                                    <img src="../../assets/img/user-icon.png" />
                                    <img class="xian" src="../../assets/img/iocn-xian.png" />
                                </span>
                                <input v-model="userName" @blur="verificationUsername" class="mid" type="text" placeholder="请输入用户名" :style="{width:calcWidth}" autofocus /><span class="symbol" v-if="showDomian">@</span><img v-if="showDomian" class="right-xian" src="../../assets/img/iocn-xian.png" /><input v-model="userDomain" class="min" type="text" placeholder="租户域名" v-if="showDomian" />
                            </div>
                            <div class="login-error" :style="{ display : rules.invalidUserName ? 'block' :''}">
                                <p :style="{ display : rules.invalidUserName ? 'block' :''}"><img src="../../assets/img/re-error.png" alt="错误" />用户名输入不正确</p>
                            </div>
                        </li>
                        <li>
                            <div class="border">
                                <span class="input-icon">
                                    <img src="../../assets/img/pass-icon.png" />
                                    <img class="xian" src="../../assets/img/iocn-xian.png" />
                                </span>
                                <input v-model="userpwd" @blur="verificationUserPwd" @keyup.enter="submitForm" class="max" type="password" placeholder="请输入密码" />
                            </div>
                            <div class="login-error" :style="{ display : rules.invalidUserPwd ? 'block' :''}">
                                <p :style="{ display : rules.invalidUserPwd ? 'block' :''}"><img src="../../assets/img/re-error.png" alt="错误" />密码输入不正确</p>
                            </div>
                        </li>
                        <!--<li v-if="IsValidateCodeShow">
                            <div class="login-validCode">
                                <input v-model="uservCode" @blur="verificationUserVCode" @keyup.enter="submitForm" type="text" class="mis" placeholder="验证码" /><img class="validCode" :src="validCodeUrl" alt="验证码" @click="refrushVCode" /><span @click="refrushVCode">看不清，换一张</span>
                            </div>
                            <div class="login-error" :style="{ display : rules.invalidUserVCode ? 'block' :''}">
                                <p :style="{ display : rules.invalidUserVCode ? 'block' :''}"><img src="../../assets/img/re-error.png" alt="错误" />验证码输入不正确</p>
                            </div>
                        </li>-->
                        <!-- <li class="img-yzm" @click="GraphicVerificationCode">
                            <img class="yzm" src="../../assets/img/iocn_yzm.jpg" />
                            <img class="dyzm" src="../../assets/img/iocn_dyzm.png" />
                            <span>点击按钮进行验证</span>
                        </li>
                        <li class="img-yzm-su">
                            <img src="../../assets/img/iocn_yzcg.png" />
                            <span>验证成功</span>
                        </li> -->
                    </ul>
                    <el-button class="login-btn CodeShowActive" type="button" @click="GraphicVerificationCode()" :loading="loading" @keyup.enter="GraphicVerificationCode">登录</el-button>
                </form>
                <p class="login-contact CodeShowActivep">{{pageSetting.text.telephone}}</p>
            </div>
        </div>
        <div class="re-footer">
            <p v-if="false">
                版权所有 © 四川物联亿达科技有限公司 | <img style="width: 70px;height: 18px;vertical-align: middle;" src="../../assets/logo/oms-login-left-top.png" /> | 保留一切权利 | 蜀ICP备12009201号-11    旗舰版V3.0
            </p>
        </div>
        <div class="GraphicVerificationCode-bg">
            <div class="arrow">
                <span></span>
            </div>
            <div class="validWay" id="__Verification">

            </div>
        </div>
    </div>
</template>
<script>
    import PageSetting from '../../scripts/pagesetting.js'
    import Cookie from '@/scripts/cookie.js'
    import { token1, Id1 } from "../../scripts/drag.js";
    import config from '@/scripts/config.js';

    export default {
        data() {
            var _this = this;
            return {
                userName: '',
                userpwd: '',
                uservCode: '',
                userDomain: '',
                validCodeUrl: '',
                loading: false,
                showDomian: false,
                pageSetting: PageSetting,
                validWay: false,
                authenticationSuccessful: 1,
                securityCode: 1,
                rules: {
                    invalidUserName: false,
                    invalidUserPwd: false,
                    //invalidUserVCode: false,
                },
                IsValidateCodeShow: false,
                loginLogo:"../../login_logo.png"
            }
        },
        created: function () {
            //this.refrushVCode();
            this.showDomian = this.getDomain() == '';
            if (!this.showDomian) this.userDomain = this.getDomain();
            //if (Cookie.loginCode != undefined && Cookie.get(Cookie.loginCode) != null && Cookie.get(Cookie.loginCode) == "loginCodeShow") {
            //    this.IsVerificationCodeShow = true;
            //}
        },
        computed: {
            calcWidth() {
                return this.getDomain() != '' ? "262px" : "142px";
            }
        },
        methods: {
            GraphicVerificationCode: function () {
                // $('.yzm').show();
                // $('.dyzm').hide();
                if (!this.userName || !this.userpwd) {
                    return;
                }
                this.verificationAll();
                if (this.rules.invalidUserName || this.rules.invalidUserPwd) return;
                $('.GraphicVerificationCode-bg').show();
            },
            submitForm: function () {
                this.verificationAll();
                console.log(token1,this.userName,this.userpwd);
                if (token1 == "" && this.userName && this.userpwd) {
                    $('.GraphicVerificationCode-bg').show();
                    // $('.yzm').show();
                    // $('.dyzm').hide();
                    return false;
                }
                //if (this.rules.invalidUserName || this.rules.invalidUserPwd) return;
                var data = {};
                data.UserName = this.userName;
                data.UserPassword = this.$crypto.sm2Encrypt(this.userpwd);
                data.DomainName = this.userDomain;
                if(this.userDomain) 
                {
                    Cookie.set(Cookie.domain, this.userDomain);
                }
                else
                {
                    Cookie.remove(Cookie.domain);
                }
                data.VerificationCode = Id1 + "," + token1;
                this.$AjaxRequest.AjaxSubmit("omsapi/auth/login", "post", data, result => this.handleLoginResult(result), result => {
                    this.loading = false;
                    this.refrushSlideCode();
                    this.uservCode = '';
                    this.Utils.messageBox(result, "error");
                    // $('.img-yzm-su').hide();
                    // $('.img-yzm').show();

                }, true);
                return false;
            },
            handleLoginResult: function (result) {
                this.loading = false;
                if (result.IsSuccess) 
                {
                    ////去除验证码验证
                    //Cookie.remove(Cookie.loginCode);
                    var domain = this.userDomain;
                    if (localStorage.wmsstatus == "true") 
                    {
                        this.$crypto.initSm2PriConfig(result.Result.SM2PrivateKeyForClient);
                    }
    
                   localStorage.wmstoken=result.Result.jwttoken;
                   Cookie.set(Cookie.domain, domain);

                    var homePath = `/${config.subdomain}/home`;
                    if (!!domain) {
                        window.location.href =  `/${config.subdomain}/${domain}/home`;
                    }else{
                        this.$router.push({
                            path: homePath
                        });
                    }
                } 
                else {
                    //alert(result.OperationDesc);
                    //if (result.ResultCode == 3) { //密码输入错误
                    //    this.IsValidateCodeShow = true;//开启验证码
                    //}
                    this.loading = false;
                    this.refrushSlideCode();
                    this.uservCode = '';
                    this.Utils.messageBox(result.OperationDesc, "error");
                    // $('.img-yzm-su').hide();
                    // $('.img-yzm').show();
                    //this.IsValidateCodeShow = true;//开启验证码
                    //Cookie.set(Cookie.loginCode, "loginCodeShow");
                    //this.IsVerificationCodeShow = true;//开启验证码
                    //this.refrushVCode();
                }
            },
            refrushSlideCode: function () {
                var _this = this;
                $("#__Verification").slide("300*200", function (ret, desc) {
                    if (!ret) {
                        _this.Utils.messageBox(desc, "error");
                        return;
                    }
                    setTimeout(() => { _this.submitForm(); }, 50);
                });
            },
            refrushVCode: function () {
                this.validCodeUrl = config.subdomain + "/operations/omsapi/getmanagevalidatecode?ts=" + Number(new Date());
                //this.validCodeUrl = "operations/omsapi/getmanagevalidatecode?ts=" + Number(new Date());
            },
            verificationAll: function () {
                this.verificationUsername();
                this.verificationUserPwd();
                //if (this.IsValidateCodeShow) {//密码错误的情况下 填写验证码
                //    this.verificationUserVCode();
                //}
            },
            verificationUsername: function () {
                this.rules.invalidUserName = this.userName.replace(/^\s+|\s+$/gm, '').length == 0;
            },
            verificationUserPwd: function () {
                this.rules.invalidUserPwd = this.userpwd.replace(/^\s+|\s+$/gm, '').length == 0;
            },
            verificationUserVCode: function () {
                this.rules.invalidUserVCode = this.uservCode.replace(/^\s+|\s+$/gm, '').length != 4;
            },
            getDomain() {
                var path = this.getVPath();

                if (path) {
                    if (path.indexOf('/') >= 0)
                        return this.trim(path, '/');
                }

                return path;
            },
            trim(str, char, type) {
                if (char) {
                    if (type == 'left') {
                        return str.replace(new RegExp('^\\' + char + '+', 'g'), '');
                    } else if (type == 'right') {
                        return str.replace(new RegExp('\\' + char + '+$', 'g'), '');
                    }
                    return str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');
                }
                return str.replace(/^\s+|\s+$/g, '');
            },
            getVPath: function () {
                var pathName = window.location.pathname.substring(1);
                pathName = pathName.replace(`/login`, '')
                pathName = pathName.replace(`/home`, '');
                pathName = pathName.replace(config.subdomain, '');
                var vPath = pathName == '' ? '' :  pathName.substring(pathName.indexOf('/'));
                return vPath == '/' ? "" : vPath;
            },
            initSm2() {
                this.$ajax.send("omsapi/common/getsm2status", "get", {}, data => {
                    localStorage.wmsstatus = data.Result;
                    if (localStorage.wmsstatus == "true") {
                        this.$ajax.send("omsapi/common/getsm2publickey", "get", {}, data => {
                            this.$crypto.initSm2PubConfig(data.Result);
                        }, null, false);
                    }
                }, null, false);
            },
            inititalWebsite(){
                var me = this;
                var domain = this.getDomain();
                if(domain){
                    this.$ajax.send("omsapi/common/getwebsite", "get", {domain:domain}, data => {
                        if(data.IsSuccess && data.Result && data.Result.LoginLogo){
                            me.loginLogo=data.Result.LoginLogo;
                            me.Utils.initialWebsite(data.Result);
                        }
                    }, null, false);
                }
            }
        },
        mounted() {
            var self = this;
            self.refrushSlideCode();
            $('body').on('click', '.drag-shut', function () {
                //self.validWay = false;
                $('.GraphicVerificationCode-bg').hide();
                //self.securityCode=1;
                // $('.yzm').hide();
                // $('.dyzm').show();
            })
            //self.mouseupDrag();
            this.initSm2();
            this.inititalWebsite();
        }
    }
</script>
<style scoped>
    @import '../../assets/css/style.css';
    @import '../../css/drag.css';
</style>